import * as types from './types';

export const initialError = { hasError: false, errorData: {} };

const initialState = {
	data: {},
	formattedRoutes: [],
	isLoading: false,
	formattedUnassignedShippings: [],
	errorStatus: initialError
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				errorStatus: initialError,
				isLoading: action.isLoading
			};

		case types.SET_ERROR:
			return {
				...state,
				isLoading: false,
				errorStatus: { hasError: true, errorData: action.status }
			};

		case types.FETCH_ROUTE_PLANNING_DATA:
			return {
				...state,
				data: action.data
			};

		case types.SET_FORMATTED_ROUTES:
			return {
				...state,
				formattedRoutes: action.routes
			};

		case types.SET_FORMATTED_UNASSIGNED_SHIPPINGS:
			return {
				...state,
				formattedUnassignedShippings: action.unassignedShippings
			};

		case types.CLEAN_STATE:
			return initialState;

		default:
			return state;
	}
}

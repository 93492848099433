/** Filters a list of stops by declaring a kind
 * @param {array} routeStops - the list of route stops
 * @param {string} type - The stop type to filter
 * @returns {array} - a new list with the filtered stops
 */
export default (routeStops = [], type = '') =>
	routeStops.filter(stop => {
		if (!stop || !stop?.type) return;
		return stop?.type === type;
	});
